import React from 'react'
import uploadImage from '../photos/uploadImage.svg'

const Upload = () => {
  return (
    <div className='import'>
        <img src={uploadImage} alt="uploadImage"  />
    </div>
  )
}

export default Upload